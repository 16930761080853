import apiApp from '@/api/config/apiApp'

function listWatchmenByResidential (residentialId) {
  return apiApp.get(`/guard/residential/${residentialId}`)
}

function createWatchman (data) {
  return apiApp.post('/guard/', data)
}

function updateWatchman (id, data) {
  return apiApp.put(`/guard/${id}`, data)
}

function changeStatusWatchman (data) {
  return apiApp.post('guard/status', data)
}

function removeWatchman (id) {
  return apiApp.delete(`/guard/${id}`)
}

export {
  listWatchmenByResidential,
  createWatchman,
  updateWatchman,
  changeStatusWatchman,
  removeWatchman
}
