<template>
  <div class="justify-content-start h-100 view-container">
    <HeaderResidential />
    <div class="mt-4">
      <div class="row">
        <div class="col-md-4 pt-2">
          <Button
            :text="$t('guard.createWatchman')"
            color="primary"
            class="d-inline"
            @clicked="newWatchman"
          />
        </div>
        <div class="col-md-6"></div>
        <div class="col-md-2">
          <div class="ui icon input" style="width: 99%">
            <input type="text" v-model="filters.text" :placeholder="$t('search')">
            <i class="search icon"></i>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-5" style="margin-left: 12px; margin-right: 13px">
        <div class="row">
          <table class="ui blue unstackable selectable celled table" style="cursor: default !important; color: #808080">
            <thead>
            <tr>
              <th>{{$t('guard.watchman')}}</th>
              <th style="width: 7%" class="text-center">{{$t('guard.edit')}}</th>
              <th style="width: 7%" class="text-center">{{$t('guard.state')}}</th>
              <th style="width: 10%" class="text-center">{{$t('guard.register')}}</th>
            </tr>
            </thead>
            <tbody v-if="watchmenOrdered.length === 0">
              <tr>
                <td colspan="8" class="text-center">
                  {{$t('guard.noWatchmen')}}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
                v-for="watchman in watchmenOrdered"
                :key="watchman"
                :class="classObjectIsOnlineRow(watchman.isOnline)"
              >
                <td @click="watchman.belongsToMaster ? () => {} : editWatchman(watchman)">
                  <h4 class="ui image header">
                    <div class="content">{{ watchman.firstName }} {{ watchman.lastName }}</div>
                  </h4>
                </td>
                <td class="text-center">
                  <a
                    href="javascript:;"
                    @click="watchman.belongsToMaster ? () => {} : editWatchman(watchman)"
                    :data-tooltip="watchman.belongsToMaster ? $t('guard.onlyMaster') : $t('guard.editWatchman')"
                    data-position="bottom center">
                    <i
                      class="pencil alternate icon"
                      :class="{'option-muted': watchman.belongsToMaster}"></i>
                  </a>
                </td>
                <td style="text-align: center">
                  <div class="ui toggle checkbox text-center">
                    <input
                      type="checkbox"
                      :disabled="watchman.belongsToMaster"
                      :checked="watchman.status === status.ACTIVE"
                      @change="changeStatus(watchman.id, $event)"
                    >
                    <label style="margin-left: -6px"></label>
                  </div>
                  <br>
                  <small>{{ $t(`status.${watchman.status}`) }}</small>
                </td>
                <td @click="watchman.belongsToMaster ? () => {} : editWatchman(watchman)" class="text-center">{{ formatDate(watchman.createdAt) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <WatchmanForm
    :show="showWatchmanForm"
    :data="watchmanForm"
    @close="closeForm"
  />

  <WatchmanUpload
    :show="showWatchmanUpload"
    @close="closeUpload"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import HeaderResidential from '../../components/ui/HeaderResidential'
import Button from '../../components/form/Button'
import WatchmanForm from './WatchmanForm.vue'
import helpers from '../../utils/helpers'
import { listWatchmenByResidential, changeStatusWatchman } from '../../api/guard'

export default {
  name: 'Guard',
  components: {
    HeaderResidential,
    Button,
    WatchmanForm
  },
  data () {
    return {
      showForm: false,
      watchmen: [],
      watchmanEdition: {},
      filters: {
        text: ''
      }
    }
  },
  computed: {
    ...mapGetters('residential', ['activeResidential']),

    watchmenOrdered () {
      let watchmen = this.watchmen

      if (this.filters.text) {
        watchmen = watchmen.filter(watchman => {
          const name = `${watchman.firstName.toLowerCase()} ${watchman.lastName.toLowerCase()}`
          return name.includes(this.filters.text)
        })
      }

      return watchmen.sort((a, b) => this.sortByName(a, b))
    },

    showWatchmanForm () {
      return this.showForm
    },

    watchmanForm () {
      return this.watchmanEdition
    }
  },
  created () {
    this.loadWatchmen()
  },
  methods: {

    async loadWatchmen () {
      this.$store.dispatch('loading/setLoading', true)
      try {
        const resp = await listWatchmenByResidential(this.activeResidential.residentialId)
        this.watchmen = resp.data
      } catch (e) {}
      this.$store.dispatch('loading/setLoading', false)
    },

    formatDate (date) {
      return helpers.formatDateDDMMYYServer(date)
    },

    sortByName (a, b) {
      const aName = (a.firstName + ' ' + a.lastName).toLowerCase()
      const bName = (b.firstName + ' ' + b.lastName).toLowerCase()
      if (aName < bName) {
        return -1
      }
      if (aName > bName) {
        return 1
      }
      return 0
    },

    changeStatus (id, e) {
      const isActive = e.target.checked
      changeStatusWatchman({ id, isActive }).then(() => {})
    },

    newWatchman () {
      this.watchmanEdition = {}
      this.showForm = true
    },

    editWatchman (watchman) {
      this.watchmanEdition = watchman
      this.showForm = true
    },

    closeForm () {
      this.showForm = false
      this.loadWatchmen()
    },

    classObjectIsOnlineRow (isOnline) {
      return {
        blue: isOnline,
        nonOnlineRow: !isOnline
      }
    }
  }
}
</script>

<style scoped>
  .ui.table {
    background: transparent;
  }

  .ui.table > thead > tr > th {
    color: #808080
  }

  .ui.image.header > .content {
    font-size: 14px;
    font-weight: normal;
  }

  .nonOnlineRow {
    color: #808080;
  }

  .nonOnlineRow:hover {
    color: #808080 !important;
  }

  .option-muted {
    opacity: .4
  }
</style>
