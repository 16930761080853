<template>
  <SuiModal v-model="dialog">
    <SuiModalContent >
          <SuiModalDescription>
            <div>
              <h2
                class="general-blue-color"
                style="font-size: 17px"
              >{{$t('guard.creationWatchmen')}}</h2>

              <div class="ui form">
                <form autocomplete="nope">
                  <div class="row mb-1">
                    <div class="col-md-3 pt-2"><label>{{$t('guard.names')}}</label></div>
                    <div class="col ui input">
                      <input
                        type="text"
                        :maxlength="maxLengthFirstName"
                        v-model="watchmanForm.firstName" autocomplete="nope">
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-md-3 pt-2"><label>{{$t('guard.lastNames')}}</label></div>
                    <div class="col ui input">
                      <input
                        type="text"
                        :maxlength="maxLengthLastName"
                        v-model="watchmanForm.lastName" autocomplete="nope">
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-md-3 pt-2"><label>{{$t('guard.phone')}}</label></div>
                    <!-- <div class="col-md-2 pt-2" v-show="isEditing">
                      <label>
                        {{ countryCodeSelected.text }}
                      </label>
                    </div> -->
                    <!-- <div class="col-md-3 ui input" v-show="!isEditing"> -->
                    <div class="col-md-3 ui input countryCodesSelect">
                      <Select
                        id="countryCodes"
                        v-model="countryCodeSelected"
                        :search="true"
                        :placeholder="$t('guard.code')"
                        :options="countriesCodeInfoToShow"
                        :selection="true"
                        :fluid="false"
                        autocomplete="nope"
                      />
                    </div>
                    <div class="col ui input">
                      <input
                        v-model="watchmanForm.phoneNumber"
                        type="text"
                        autocomplete="nope"
                      >
                    </div>
                  </div>
                  <div class="row mb-1 password-field">
                    <div class="col-md-3 pt-2 flex gap-1">
                      <div class="help">
                        <label>{{$t('guard.password')}}</label>
                        <div
                          class="upper-left-label"
                          :data-tooltip="$t('guard.passwordHelp')"
                          data-position="bottom left"
                          data-variation="small wide"
                        >
                          <i style="color: #436f98" class="question circle outline icon"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col ui input">
                      <input
                        :type="showPassword ? 'text' : 'password'"
                        v-model="watchmanForm.password"
                        :maxlength="maxLengthPassword"
                        :minlength="minLengthPassword"
                        autocomplete="new-password"
                        :placeholder="passwordPlaceholder"
                        @focus="passwordPlaceholder = ''"
                        @blur="passwordPlaceholder = '••••••••'"
                      >
                      <i
                        :class="showPassword ? 'eye slash icon' : 'eye icon'"
                        @click="togglePasswordVisibility"
                        style="cursor: pointer; margin-left: -25px; position: relative; top: 13px;"
                      ></i>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-md-3 pt-2"><label>{{$t('guard.state')}}</label></div>
                    <div class="col ui input pt-2">
                      {{$t('status.INACTIVE')}}
                      <div
                        class="ui toggle checkbox text-center"
                        style="margin-left: 10px; margin-right: 16px; padding-top: 4px;"
                      >
                        <input type="checkbox" v-model="watchmanForm.isActive">
                        <label style="height: 0px; width:0px"></label>
                      </div>
                      {{$t('status.ACTIVE')}}
                    </div>
                  </div>
                </form>

                <div
                  class="errorMessageValidation"
                  v-if="v$.watchmanForm.$errors.length">
                  <!-- <p
                    v-for="(error, index) in v$.watchmanForm.$errors"
                    :key="index"
                    class="error text-center mb-1"
                  >{{ error.$message }}</p> -->
                  <p
                    class="text-danger"
                  >{{ v$.watchmanForm.$errors[0].$message }}</p>
                </div>

                <!--  mt-5 -->
                <div
                  class="text-center mb-4"
                  :style="v$.watchmanForm.$errors.length?'margin-top: 1rem !important':'margin-top: 3rem !important'"
                >
                  <div v-if="isEditing">
                    <Button
                      :text="$t('guard.deleteWatchman')"
                      @clicked="remove"
                      color="third-color"
                      class="d-inline"
                      :appearance="{ height: '29px' }"
                    />
                    <Button
                      :text="$t('buttons.modified')"
                      @clicked="save"
                      color="primary"
                      class="d-inline"
                      style="margin-left: 20px"
                    />
                  </div>
                  <div v-else>
                    <Button :text="$t('buttons.create')" @clicked="save" color="primary" class="d-inline" />
                  </div>
                </div>
              </div>
            </div>
          </SuiModalDescription>
          <a @click="close">
            <i style="text-decoration:none !important; cursor: pointer;font-size: 25px;"
            class="times icon general-blue-color"></i>
          </a>
    </SuiModalContent>
  </SuiModal>
</template>

<script>
import {
  SuiModal,
  SuiModalContent,
  SuiModalDescription
} from 'vue-fomantic-ui'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, minLength, maxLength, numeric } from '@vuelidate/validators/dist/raw.esm'
import { mapGetters } from 'vuex'
import Button from '../../components/form/Button'
import Select from '../../components/form/Select'
import countryCodes from '../../utils/countryCodes'
import { createWatchman, updateWatchman, removeWatchman } from '../../api/guard'
import helper from '@/utils/helpers'

export default {
  name: 'WatchmanForm',
  components: {
    SuiModal,
    SuiModalContent,
    SuiModalDescription,
    Select,
    Button
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    data: Object
  },
  watch: {
    show () {
      if (this.show) {
        const { firstName, lastName, countryCode, phoneNumber, password, status } = this.data
        this.isEditing = !!this.data.id
        this.watchmanForm = {
          firstName,
          lastName,
          countryCode: Object.entries(countryCodes.countryCallCodes).find((number) => number[1] === countryCode),
          // countryCode: countryCodes.countryCallCodes[countryCode],
          phoneNumber,
          password,
          isActive: status === this.status.ACTIVE
        }
        if (this.isEditing) {
          const codeCountry = Object.entries(countryCodes.countryCallCodes).find((x) => x[1] === Number(countryCode))[0]
          this.countryCodeSelected = {
            value: codeCountry,
            text: `${codeCountry} (+${countryCode})`,
            flag: codeCountry
          }
        }
      }
    },
    countryCodeSelected ({ value }) {
      if (value && value !== '') {
        this.watchmanForm.countryCode = countryCodes.countryCallCodes[value].toString()
      }
    }
  },
  data () {
    return {
      isEditing: false,
      countryCodeSelected: '',
      watchmanForm: {
        firstName: '',
        lastName: '',
        countryCode: '',
        phoneCountryCode: '',
        phoneNumber: '',
        password: '',
        isActive: false
      },
      maxLengthPhoneNumber: 10,
      minLengthPhoneNumber: 5,
      maxLengthFirstName: 20,
      maxLengthLastName: 20,
      maxLengthPassword: 8,
      minLengthPassword: 4,
      showPassword: false,
      passwordPlaceholder: '••••••••'
    }
  },
  updated () {
    const countryCodeField = document.getElementById('countryCodes').children[3]
    // console.log(countryCodeField)
    countryCodeField.style['max-height'] = '400%'
  },
  setup: () => ({ v$: useVuelidate() }),
  validations () {
    return {
      watchmanForm: {
        firstName: {
          required: helpers.withMessage(this.$t('guard.nameRequired'), required),
          alpha: helpers.withMessage(this.$t('guard.nameOnlyLetters'), helper.validateFLNameFormat),
          maxLengthValue: helpers.withMessage(`${this.$t('guard.nameMax')} ${this.maxLengthFirstName} ${this.$t('guard.characters')}`, maxLength(this.maxLengthFirstName))
        },
        lastName: {
          required: helpers.withMessage(this.$t('guard.lastNameRequired'), required),
          alpha: helpers.withMessage(this.$t('guard.lastNameOnlyLetters'), helper.validateFLNameFormat),
          maxLengthValue: helpers.withMessage(`${this.$t('guard.nameMax')} ${this.maxLengthLastName} ${this.$t('guard.characters')}`, maxLength(this.maxLengthLastName))
        },
        countryCode: {
          required: helpers.withMessage(this.$t('guard.countryRequired'), required)
        },
        phoneNumber: {
          required: helpers.withMessage(this.$t('guard.phoneRequired'), required),
          numeric: helpers.withMessage(this.$t('guard.validPhone'), numeric),
          minLengthValue: helpers.withMessage(`${this.$t('guard.minPhone')} ${this.minLengthPhoneNumber} ${this.$t('guard.digits')}`, minLength(this.minLengthPhoneNumber)),
          maxLengthValue: helpers.withMessage(`${this.$t('guard.maxPhone')} ${this.maxLengthPhoneNumber} ${this.$t('guard.digits')}`, maxLength(this.maxLengthPhoneNumber))
        },
        password: {
          required: helpers.withMessage(this.$t('guard.passwordRequired'), required),
          minLengthValue: helpers.withMessage(`${this.$t('guard.minPassword')} ${this.minLengthPassword} ${this.$t('guard.characters')}`, minLength(this.minLengthPassword)),
          maxLengthValue: helpers.withMessage(`${this.$t('guard.maxPassword')} ${this.maxLengthPassword} ${this.$t('guard.characters')}`, maxLength(this.maxLengthPassword))
        }
      }
    }
  },
  computed: {
    ...mapGetters('residential', ['activeResidential']),
    dialog () {
      return this.show
    },
    watchman () {
      this.setData()
      if (this.data === {}) {
        return {
          firstName: '',
          lastName: '',
          phoneNumber: '',
          password: '',
          isActive: false
        }
      } else {
        const { firstName, lastName, phoneNumber, password, status } = this.data
        return {
          firstName,
          lastName,
          phoneNumber,
          password,
          isActive: status === this.status.ACTIVE
        }
      }
    },
    countriesCodeInfoToShow () {
      const codes = Object.keys(countryCodes.countryCodes).map(abbrv => ({
        id: abbrv,
        description: `${abbrv.trim()} (+${countryCodes.countryCallCodes[abbrv]})`,
        flag: abbrv.trim()
      }))
      return [
        ...codes.filter(x => x.id === 'co'),
        ...codes.filter(x => x.id !== 'co')
      ]
    }
  },
  methods: {

    setData () {
      if (this.data === {}) {
        this.clearForm()
      } else {
        const { firstName, lastName, phoneNumber, password, status } = this.data
        this.watchmanForm = {
          firstName,
          lastName,
          phoneNumber,
          password,
          isActive: status === this.status.ACTIVE
        }
      }
    },

    // watchmanAlreadyExist (watchman) {
    //   console.log(watchman)
    //   return false
    // },

    async save () {
      if (this.validateForm() === false) {
        return
      }

      this.$store.dispatch('loading/setLoading', true)
      const data = { ...this.watchmanForm, residentialId: this.activeResidential.residentialId }
      try {
        if (this.isEditing) {
          const id = this.data.id
          await updateWatchman(id, data)
          this.$swal(this.$t('guard.watchmanModified'), '', 'success')
        } else {
          await createWatchman(data)
          this.$swal(this.$t('guard.watchmanCreated'), '', 'success')
        }
        this.close()
      } catch (error) {
        // console.log(error.response)
        if (error.response.status === 400) {
          this.$swal(error.response.data.message, '', 'error')
        } else {
          this.$swal(this.$t('errors.tryLater'), '', 'error')
        }
      }
      this.$store.dispatch('loading/setLoading', false)
    },

    validateForm () {
      this.v$.watchmanForm.$touch()
      const isValid = !(this.v$.watchmanForm.$error)
      if (!isValid) {
        return false
      }
      return true
    },

    remove () {
      this.$swal({
        title: `${this.$t('guard.deleteWatchmanQuestion')} ${this.data.firstName} ${this.data.lastName}?`,
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        confirmButtonColor: '#dc3545',
        cancelButtonText: this.$t('no'),
        cancelButtonColor: '#2186b0'
      }).then(async (result) => {
        if (result.isConfirmed) {
          removeWatchman(this.data.id).then(() => {
            this.$swal(`${this.data.firstName} ${this.data.lastName} ${this.$t('guard.watchmanDeleted')}`, '', 'success')
            this.close()
          }).catch(() => {
            this.$swal(this.$t('errors.tryLater'), '', 'error')
          })
        }
      })
    },

    clearForm () {
      this.watchmanForm.firstName = ''
      this.watchmanForm.lastName = ''
      this.watchmanForm.countryCode = ''
      this.watchmanForm.phoneCountryCode = ''
      this.watchmanForm.phoneNumber = ''
      this.watchmanForm.password = ''
      this.watchmanForm.isActive = false
      this.isEditing = false
      this.countryCodeSelected = ''
      this.v$.$reset()
    },

    close () {
      this.clearForm()
      this.$emit('close')
    },
    togglePasswordVisibility () {
      this.showPassword = !this.showPassword
    }
  }
}
</script>

<style scoped>
  /* .modal-container {
    padding: 20px;
    background-color: #fff;
    color: black;
    width: 655px;
    border-radius: 20px;
    padding: 20px;
    padding-top: 10px;
    webkit-box-shadow: 3px 5px 6px 0px rgb(0 0 0 / 41%);
    box-shadow: 3px 5px 6px 0px rgb(0 0 0 / 41%);
    z-index: 99;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  } */

  div[class^="menu transition"] {
    max-height: 400% !important
  }

  .errorMessageValidation {
    width:100%;
    align-self:center;
    display: flex;
    justify-content:center;
  }

  .password-field {
    position: relative;
  }

  .help {
    display: flex;
    gap: 4px;
  }
</style>
